.launchingsoon_logo {
  width: 40px;
  height: 40px;
  background: url("../src/assets/icons//svg//launchingsoon_icon.svg") no-repeat
    center center;
  background-size: 100% 100%;
}

.dashboard_box:hover .launchingsoon_logo {
  background: url("../src/assets//icons//svg//launchingsoon_icon_color.svg")
    no-repeat center center;
  filter: none;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

.cipherAnts_logo {
  background: url("../src//assets/icons/svg/cipherants.svg") no-repeat center
    center;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

.dashboard_box:hover .cipherAnts_logo {
  background: url("../src/assets/icons/svg/cipherants_color.svg") no-repeat
    center center;
  filter: none;
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
}

.chat_box_head_search {
  background: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #61616136;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.eazycrm-logo{
  height: 75px;
  width: 209px;
  display: block;
  margin: 0px 0px;
  object-fit: cover;
  padding-top: 14px;
  cursor:pointer;
}

.contentscroll::-webkit-scrollbar {
  width:0px;
}

.scroll-color::-webkit-scrollbar {
  width:0px;
}

.links-hover{
  text-decoration: none;
}

.links-hover:hover {
  opacity: 0.8; 
}

.phone-inputchat input {
  border-radius: 5px !important;
  border: 1px solid transparent; 
}

.phone-inputchat input:focus {
  border: 1px solid #0292d0; 
}

.e-logo {
  width: 56px;
  height: 45px;
  background: url("../src/assets/images/e-logo.png") no-repeat center center;
  /* background: url("../src/assets/icons//svg/logo_icon.svg") no-repeat center center; */
  background-size: 100% 100%;
  cursor:pointer;
}

.left-right {
  font-size: 17px;
  transition: color 0.3s;
}


/* .PhoneInput .PhoneInput--focus{
  border: none;
  outline: none;
} */
.PhoneInput input {
  
  outline: none;
  height: 29px;
  background-color: #8080801f;
  padding: 5px;
  padding-left: 20px;
  border-radius: 17px;
  border: none;
  font-weight: 600;


}

.PhoneInputCountry {
  width: 40px;
}

.PhoneInput {
  border: 1px solid black;
  /* width: 60px; */
}

/* .PhoneInput--focus {
  border: 2px solid #BB3138;
} */
