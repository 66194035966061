body {
  margin: 0;
  font-family: "Inter", sans-serif;
  /* background-color: #F9F9F9; */
}

code {
  font-family: "Inter", sans-serif;
}

.btnInput {
  height: 100%;
  width: 100%;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.btnInput:focus {
  outline: none;

}


.mainbuttonbox{
  height: 216px;
  overflow-y: auto;
  overflow-x:hidden;
  border-radius:5px;
  border-width: 1px;
  border-style: solid;
}

.css-dpa24h {
  border-radius: 15px;
  top: 0px;
  height: 100%;
  background-color: rgb(52 52 52);
  width: 100%;
}

.css-e1lbvo {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  background-color: #525252;
  color: white;
  width: 45px;
  height: 45px;
}

.css-120tbr8 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: #666565;
  background: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

.css-1678nd2 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: #666565;
  background: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}

.css-1s210ti {
  margin: 0px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 300;
}

.css-1vn8mzh {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(255, 255, 255);
  border: 1px solid red;
  width: 50px;
  height: 50px;
  background-color: #424240;
  margin-left: 12px;
}


.css-1taphh4 {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #424242;
}

.css-dpa24h {
  border-radius: 15px;
  top: 0px;
  height: 100%;
  background-color: #424242;
  width: 100%;
}

/* .css-awjscg {
  max-height: 320px;
  margin: 8px;
  overflow-y: scroll;
  background-color: whitesmoke;
  border-radius: 15px;
  position: fixed;
} */

.chatlist::-webkit-scrollbar {
  width: 0px;         
}

.MuiOutlinedInput-input,
.MuiInputLabel-root {
  font-size: 0.94rem !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
  font-size: 1rem !important;
}

.epr-search-container input{
  background-color:transparent !important;
}

.epr-search-container input{
  border:1px solid black;
}

.epr-emoji-category-label{
  color:black !important;
}

input::file-selector-button {
  font-weight: 500;
  color: rgb(255, 255, 255) !important;
  padding: 6px 10px 7px 10px;
  border: thin solid #0293d2;
  border-radius: 3px !important;
  font-size:15px;
  cursor:pointer !important;
  background: #0293d2 !important;
}

input::file-selector-button:hover {
  background: #0278c0 !important; 
}

.imagetype input{
  padding: 9px 8px 18px 12px !important;
}

.imagetype:before{
  border-bottom: 1px solid #808080 !important;
}

.delete-icon:hover {
  color: #e81c2b !important;
}
.edit-icon:hover{
  color:#0292d0 !important;
}

@media (max-width: 600px) {
  .modalBox {
    width: 314px !important;
  }
}




.otp-input-root input{
  border-radius:5px !important;
}
.otp-input-root input:focus{
  border-color:#0293d2 !important;
}

.otpInputStyle {
  border: 1px solid rgb(2, 147, 210);
  border-radius: 5px;
  font-size: 16px;
  height: 28px !important; 
  width: 28px !important;  
  margin-right: 9px !important;
  margin-top: 13px;
}

@media (min-width: 768px) {
  /* Styles for tablets and larger devices */
  .otpInputStyle {
    height: 40px !important;
    width: 40px !important;
    margin-right: 13px !important;
    margin-top: 13px;

  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
